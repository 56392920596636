import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// fetch 请求  用于处理关闭刷新界面请求接口问题
import 'whatwg-fetch';
// ui框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
console.log(process.env.VUE_APP_AXIOS_URL);
// 请求封装
import http from './api/api.js';
Vue.prototype.$http = http;

//element ui 提示唯一显示封装
import gtMessage from './message';
Vue.prototype.$message = gtMessage;
// 成功调用方式：this.$message.success(options);
// 失败调用方式：this.$message.error(options);
// 警告调用方式：this.$message.warning(options);
// 提示调用方式：this.$message.info(options);

// 输入框正则判断
import check from '../util/util.js';
Vue.prototype.$check = check;

// 多语言
import en from './locales/en';
import zh from './locales/zh';
import sa from './locales/sa';
import createI18n from 'vue-i18n';

// 组合语言包对象
const messages = {
    en,
    zh,
    sa
};

Vue.use(createI18n);
// 创建实例对象
const i18n = new createI18n({
    legacy: false, // 设置为 false，启用 composition API 模式
    messages,
    locale: localStorage.getItem('language') || 'zh'
});

Vue.mixin({
    data() {
        return {
            configUser: {}, //用户信息
            config_con: {}, //用户信息
            userinfoType: false //是否登录状态
        };
    },
    mounted() {
        if (localStorage.getItem('jx-token') && localStorage.getItem('jx-userinfo')) {
            this.userinfoType = true;
        }
        if (localStorage.getItem('jx-userinfo')) {
            this.configUser = JSON.parse(localStorage.getItem('jx-userinfo'));
        }
        if (localStorage.getItem('jx-config')) {
            this.config_con = JSON.parse(localStorage.getItem('jx-config'));
        }
        if (localStorage.getItem('jx-token') && localStorage.getItem('jx-userinfo') == null) {
            // 防止mixin 多次调用接口 记录当前界面url
            if (localStorage.getItem('jx-router') == null || localStorage.getItem('jx-router') != window.location.pathname) {
                localStorage.setItem('jx-router', window.location.pathname);
                this.configUserinfo();
            }
        }
    },
    methods: {
        checkLogin() {
            if (!localStorage.getItem('jx-token') || !localStorage.getItem('jx-userinfo')) {
                this.$parent.showLogin();
                return;
            }
        },
        localData() {
            console.log('localData');
            if (localStorage.getItem('jx-token') && localStorage.getItem('jx-userinfo')) {
                this.userinfoType = true;
            }
            if (localStorage.getItem('jx-userinfo')) {
                this.configUser = JSON.parse(localStorage.getItem('jx-userinfo'));
                console.log(this.configUser);
            }
        },
        // 用户信息
        configUserinfo() {
            this.$http.userinfo().then((res) => {
                if (res == undefined) {
                    return;
                }
                if (res.data.code == 1) {
                    this.configUser = res.data.data;
                    this.userinfoType = true;
                    console.log(this.userinfoType + 'mixin');
                    localStorage.setItem('jx-userinfo', JSON.stringify(res.data.data));
                } else {
                    localStorage.removeItem('jx-token');
                    localStorage.removeItem('jx-userinfo');
                    localStorage.removeItem('jx-router');
                    this.userinfoType = false;
                    console.log(this.userinfoType);
                }
                window.location.reload();
            });
        },
        addcart(id) {
            this.checkLogin();
            this.$http
                .addcart({
                    goods_id: id,
                    item_id: 0,
                    goods_num: 1
                })
                .then((res) => {
                    console.log(res);
                    this.$message.success('添加成功');
                });
        },
        goodDetailsHerf(id) {
            console.log(id);
            this.$router.replace({
                path: '/goodDetails',
                query: {
                    id: id
                }
            });
        },
        orderDetailsHerf(id, order_sn) {
            this.$router.replace({
                path: '/orderDetails',
                query: {
                    id: id,
                    order_sn: order_sn
                }
            });
        }
    }
});
// 手机号码过滤
Vue.filter('phone', function (value) {
    if (!value) return '';
    let str = value;
    str = str.toString().replace(/^(\d{3})(\d{4})(\d{4})/g, '$1****$3');
    return str;
});
//   身份证号码过滤
Vue.filter('ID', function (value) {
    if (!value) return '';
    let str = value;
    str = str.toString().replace(/(\d{4})\d{10}(\d{4})/, '$1********$2');
    return str;
});
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
