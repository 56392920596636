import axios from 'axios';
import Vue from 'vue';
import router from '../router/index';
// axios.defaults.timeout = 5000;                        //响应时间

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use(
    (config) => {
        config.headers['lang'] = localStorage.getItem('language') || 'zh';
        if (localStorage.getItem('jx-token')) {
            config.headers['token'] = localStorage.getItem('jx-token');
        }
        return config;
    },
    (err) => {
        window.console.log('请求超时');
        return Promise.resolve(err);
    }
);
// 统一的错误参数处理
axios.interceptors.response.use(
    (data) => {
        console.log();
        if (data.data.code == 200) {
        } else {
            if (data.data.message == '登录失效') {
                localStorage.removeItem('jx-token');
                localStorage.removeItem('jx-userinfo');
                localStorage.removeItem('jx-router');
            }
            Vue.prototype.$message.error(data.data.message);
        }
        return data;
    },
    (error) => {
        window.console.log(error);
    }
);
export default axios;
